window.$ = require("jquery")
window.bootstrap = require("bootstrap");

import Alpine from 'alpinejs';

// $(function () {
//     $('[data-toggle="tooltip"]').tooltip()
// });

window.Alpine = Alpine;

Alpine.start();
